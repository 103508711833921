import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { LoginEsqueciSenhaComponent } from '../login-esqueci-senha/login-esqueci-senha.component';

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.css'],
})
export class FormLoginComponent {
  loading = false;
  error: string | null = null;
  hide = true;

  form: FormGroup;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  onSubmit() {
    if (this.form.get('username').value && this.form.get('password').value) {
      this.obterToken();
    } else {
      this.snackBar.open('Favor informar username e Senha!', 'Info!', {
        duration: 2000,
      });
    }
  }

  private obterToken() {
    const username = this.form.get('username').value;
    const password = this.form.get('password').value;

    this.authService.obterToken(username, password).subscribe({
      next: (response) => {
        const token = JSON.stringify(response);
        localStorage.setItem('token', token);
        this.router.navigate(['/home']);
      },
      error: (errorResponse) => {
        this.snackBar.open('Erro!', errorResponse.error.error, {
          duration: 2000,
        });
      },
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginEsqueciSenhaComponent, {
      disableClose: true,
      // data: { equipamento: selectedEquipamentos, filtro: this.filtro },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
