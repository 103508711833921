<div class="container">
  <mat-dialog-content>
    <span>{{ data.message }}</span>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button
      style="width: 30%"
      mat-raised-button
      class="{{ class }}"
      (click)="confirm()"
    >
      Fechar
    </button>
  </mat-dialog-actions>
</div>
