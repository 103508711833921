<ng-container *ngIf="!loading && !error">
  <div class="fluxo-veicular">
    <div class="row">
      <form [formGroup]="filtroForm">
        <div class="row m-2 justify-content-end">
          <div class="col-md-4 col-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend" (click)="dt_inicial.open()">
                  <span class="input-group-text">
                    <!-- <i class="fa fa-thumb-tack"></i> -->
                    <i class="fa fa-user"></i>
                  </span>
                </div>
                <select
                  class="custom-select"
                  id="status"
                  name="status"
                  formControlName="funcionario"
                  (change)="getData()"
                >
                  <option value="" selected disabled hidden>
                    Selecione um funcionário
                  </option>
                  <option
                    *ngFor="let funcionario of selectFuncionario"
                    [value]="funcionario.id"
                  >
                    {{ funcionario.nome }}
                  </option>
                </select>
                <mat-datepicker #dt_inicial></mat-datepicker>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-6 my-1">
        <div class="row m-1 card py-4">
          <div class="col-12">
            <h2 class="text-secondary text-center">Total de Viagens</h2>
          </div>
          <div
            class="col-12 d-flex justify-content-center align-items-center"
            style="height: 200px"
          >
            <span class="display-1">{{ totalViagens }}</span>
          </div>
          <div class="col-12 d-flex justify-content-center align-items-center">
            <span class="display-6"
              >Chegadas {{ totalChegadas }} - Saídas {{ totalSaidas }}</span
            >
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-6 my-1">
        <div class="row m-1 card py-4">
          <div class="col-12">
            <h2 class="text-secondary text-center">Viagens por Funcionário</h2>
            <ngx-charts-bar-horizontal
              [view]="view"
              [scheme]="colorScheme"
              [results]="totalFuncionariosArray"
              [gradient]="gradient"
              [legend]="true"
              [legendTitle]="'Setembro 2024'"
              [legendPosition]="legendPosition"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [barPadding]="10"
            >
              <ng-template #tooltipTemplate let-model="model">
                <div class="m-2">{{ model.value }}</div>
              </ng-template>
            </ngx-charts-bar-horizontal>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12 mb-5">
        <div class="card m-1 py-4">
          <h2 class="text-secondary text-center">Mapa</h2>
          <div class="map-container">
            <app-map [dataAgrupado]="dataAgrupado"></app-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<div class="loading" *ngIf="loading" layout="row" layout-align="center center">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  <span class="loading-text">Carregando dados...</span>
</div>
