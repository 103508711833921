<div class="content-wrapper pb-2">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <!-- Título página aqui -->
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </section>
</div>
