import { DatePipe } from '@angular/common';
import { Component, OnDestroy, AfterViewInit, Input } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map-dashboard',
  templateUrl: './map-dashboard.component.html',
  styleUrls: ['./map-dashboard.component.css'],
  providers: [DatePipe],
})
export class MapDashboardComponent implements AfterViewInit, OnDestroy {
  @Input() dataAgrupado!: any;

  private map!: L.Map;
  constructor(private datePipe: DatePipe) {}

  private initMap(): void {
    this.map = L.map('map', {
      center: [-15.827, -49.836],
      zoom: 10,
    });

    const tiles = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 18,
        minZoom: 3,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }
    );

    tiles.addTo(this.map);

    this.dataAgrupado.forEach((funcionario: any) => {
      const nomeFuncionario = funcionario.funcionario?.nome || 'Desconhecido';

      funcionario.registros.forEach((registro: any) => {
        const chegada = registro.registro_chegada;
        const saida = registro.registro_saida;

        const dataHoraChegada =
          this.datePipe.transform(chegada?.data_hora, 'dd/MM/yyyy HH:mm:ss') ||
          'Não disponível';
        const dataHoraSaida =
          this.datePipe.transform(saida?.data_hora, 'dd/MM/yyyy HH:mm:ss') ||
          'Não disponível';

        const marker = L.marker([
          parseFloat(chegada?.latitude || saida?.latitude || '0'),
          parseFloat(chegada?.longitude || saida?.longitude || '0'),
        ]).bindPopup(
          `<strong>Funcionário:</strong> ${nomeFuncionario}<br>
           <strong>Data/Hora Chegada:</strong> ${
             dataHoraChegada || 'Não disponível'
           }<br>
           <strong>Data/Hora Saída:</strong> ${
             dataHoraSaida || 'Não disponível'
           }<br>
           <strong>Latitude/Longitude Chegada:</strong> ${
             chegada?.latitude || 'N/A'
           } - ${chegada?.longitude || 'N/A'}<br>
           <strong>Latitude/Longitude Saída:</strong> ${
             saida?.latitude || 'N/A'
           } - ${saida?.longitude || 'N/A'}<br>
           <strong>Endereço Chegada:</strong> ${
             chegada?.endereco || 'Não disponível'
           }<br>
           <strong>Endereço Saída:</strong> ${
             saida?.endereco || 'Não disponível'
           }<br>`
        );

        marker.addTo(this.map);
      });
    });
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.map.remove();
    }
  }
}
