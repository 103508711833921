import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  dadosGlobais: any;

  constructor() {
    // Inicialize os dados globais a partir do localStorage, se existirem
    const token = localStorage.getItem('token');
    const usuarioString = localStorage.getItem('usuario');
    const usuario = usuarioString ? JSON.parse(atob(usuarioString)) : null;
    const ability = localStorage.getItem('ability')
      ? JSON.parse(atob(localStorage.getItem('ability')))
      : null;

    this.dadosGlobais = {
      token,
      usuario,
      ability,
    };
  }

  setDadosGlobais(dados: any) {
    this.dadosGlobais = dados;
    localStorage.setItem('ability', btoa(JSON.stringify(dados.ability)));
    localStorage.setItem('token', btoa(JSON.stringify(dados.token)));

    if (dados.usuario) {
      localStorage.setItem('usuario', btoa(JSON.stringify(dados.usuario)));
    } else {
      localStorage.removeItem('usuario');
    }
  }

  getDadosGlobais() {
    return this.dadosGlobais;
  }

  getAbility() {
    return this.dadosGlobais.ability;
  }
}
