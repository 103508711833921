import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-login-esqueci-senha',
  templateUrl: './login-esqueci-senha.component.html',
  styleUrls: ['./login-esqueci-senha.component.css'],
})
export class LoginEsqueciSenhaComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LoginEsqueciSenhaComponent>
  ) {
    if (data) {
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
