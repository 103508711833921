import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class PaginatorService {
  // constructor(private matPaginatorIntl: MatPaginatorIntl) {
  //   this.configurePaginator();
  // }

  // private configurePaginator() {
  //   this.matPaginatorIntl.itemsPerPageLabel = 'Itens por páginas:';
  //   this.matPaginatorIntl.nextPageLabel = 'Próxima página';
  //   this.matPaginatorIntl.previousPageLabel = 'Página anterior';
  //   this.matPaginatorIntl.firstPageLabel = 'Primeira página';
  //   this.matPaginatorIntl.lastPageLabel = 'Última página';
  //   this.matPaginatorIntl.getRangeLabel = this.getCustomRangeLabel();
  // }

  // private getCustomRangeLabel() {
  //   return (page: number, pageSize: number, length: number): string => {
  //     if (length === 0 || pageSize === 0) {
  //       return `0 de ${length}`;
  //     }

  //     const startIndex = page * pageSize;
  //     const endIndex =
  //       startIndex < length
  //         ? Math.min(startIndex + pageSize, length)
  //         : startIndex + pageSize;

  //     return `${startIndex + 1} - ${endIndex} de ${length}`;
  //   };
  // }

  static getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = 'Itens por página:';
    paginatorIntl.nextPageLabel = 'Próxima página';
    paginatorIntl.previousPageLabel = 'Página anterior';
    paginatorIntl.firstPageLabel = 'Primeira página';
    paginatorIntl.lastPageLabel = 'Última página';
    paginatorIntl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }
      const startIndex = page * pageSize;
      const endIndex = Math.min(startIndex + pageSize, length);

      return `${startIndex + 1} - ${endIndex} de ${length}`;
    };

    return paginatorIntl;
  }
}
