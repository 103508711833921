<aside class="main-sidebar elevation-4" style="background-color: #064240">
  <div class="d-flex justify-content-center align-items-center">
    <a href="#" class="brand-link logo-container">
      <img src="assets/logo.svg" alt="Logo" class="responsive-logo" />
    </a>
    <p style="color: white; font-size: 16px">Viagens</p>
  </div>
  <div class="sidebar">
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <li class="nav-item menu-open">
          <a
            [routerLink]="['/home']"
            class="nav-link"
            [ngClass]="{ active: isActive('/home') }"
          >
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>Dashboard</p>
          </a>
        </li>
        <li class="nav-item">
          <a
            [routerLink]="['/viagens']"
            class="nav-link"
            [routerLinkActive]="['active']"
          >
            <i class="nav-icon fa-solid fa-car-side"></i>
            <p>Gerenciar Viagens</p>
          </a>
        </li>
        <li class="nav-item">
          <a
            [routerLink]="['/relatorios']"
            class="nav-link"
            [routerLinkActive]="['active']"
          >
            <i class="nav-icon fa-solid fa-file-lines"></i>
            <p>Relatórios</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="perfil == 1">
          <a
            [routerLink]="['/usuario']"
            class="nav-link"
            [routerLinkActive]="['active']"
          >
            <i class="nav-icon fa-solid fa-users"></i>
            <p>Usuários</p>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</aside>
