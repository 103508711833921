<div class="container mt-4">
  <h1 class="text-center">Termos de Uso</h1>
  <p class="text-end">Data: 10 de outubro de 2024</p>

  <h3>1. Introdução</h3>
  <p>Bem-vindo ao aplicativo GOINFRA Viagens.</p>
  <p>
    Agradecemos por usar o aplicativo de viagens da Agência Goiana de
    Infraestrutura e Transportes – GOINFRA e os serviços e recursos que
    disponibilizamos a vocês como parte dela (coletivamente, o “Serviço”). Por
    favor, leia atentamente os Termos de Uso e o
    <a [routerLink]="['/aviso-privacidade']">Aviso de Privacidade</a>
    antes de utilizar este site/aplicativo.
  </p>

  <h3>2. Nosso Serviço</h3>
  <p>
    Este aplicativo de registro de viagens foi elaborado para otimizar os
    registros de viagens e relatórios de atividades realizadas pelas equipes que
    atuam nas obras. Este serviço permite que você realize o registro de
    chegadas e saídas em atividades externas.
  </p>

  <h3>3. Propriedade do site e condições de Uso</h3>
  <p>
    Este aplicativo é propriedade da Agência Goiana de Infraestrutura e
    Transportes – GOINFRA, com sede na Avenida Governador José Ludovico de
    Almeida, 20 - Conj. Caiçara, Goiânia - GO, 74775-013, inscrita no CNPJ/MF
    sob nº 03.520.933/0001-06. Os direitos autorais e responsabilidade pelo
    conteúdo neste aplicativo são de total e irrestrita propriedade da GOINFRA.
  </p>
  <p>
    Estes termos de uso também estão subordinados ao aviso de privacidade. Caso
    não concorde com as disposições destes termos de uso e do aviso de
    privacidade, você não deve utilizá-lo.
  </p>
  <p>
    Ao utilizar este aplicativo, você concorda integralmente com as presentes
    normas e compromete-se a observá-las, sob o risco de aplicação de
    penalidades cabíveis.
  </p>

  <h3>Do cadastro e acesso</h3>
  <p>
    O acesso às funcionalidades do aplicativo será disponibilizado apenas aos
    usuários que possuem as credenciais de acesso (login e senha). Caso você não
    possua estas credenciais, ou ocorra erro no registro de acesso ao
    aplicativo, você deverá entrar em contato com o nosso
    <a href="http://glpi.goinfra.go.gov.br/">suporte</a>.
  </p>

  <p>
    Você é responsável por garantir a exatidão e por manter a segurança de suas
    informações de identificação. Também é responsável por todas as atividades
    que ocorrerem sob sua conta ou senha.
  </p>
  <p>
    Se você achar que há possíveis problemas relacionados à segurança de sua
    conta no aplicativo, informe-nos imediatamente para que possamos tomar as
    medidas necessárias.
  </p>
  <p>
    Por meio do seu endereço de e-mail funcional e telefone, o aplicativo
    realizará todas as comunicações necessárias com você.
  </p>
  <p>
    Cabe a você assegurar que seu equipamento seja compatível com as
    características técnicas que viabilizem a utilização do site/aplicativo e
    dos serviços.
  </p>
  <p>
    Você poderá, a qualquer momento, requerer a alteração de seu cadastro junto
    ao aplicativo. A solicitação de alteração será analisada pela equipe de
    suporte, depois de verificada a sua situação funcional.
  </p>
  <p>
    São utilizadas todas as soluções técnicas à disposição da GOINFRA para
    permitir o registro das informações de localização e a anexação de
    documentos dentro do horário de funcionamento da agência, bem como o acesso
    às informações do aplicativo (vinte e quatro) horas por dia, 7 (sete) dias
    por semana.
  </p>
  <p>
    No entanto, a navegação poderá ser interrompida, limitada ou suspensa para
    atualizações, modificações ou qualquer ação necessária ao seu bom
    funcionamento.
  </p>

  <h3>5. Responsabilidades</h3>
  <p>É de sua responsabilidade:</p>
  <ul>
    <li>
      Os defeitos ou vícios técnicos originados no seu dispositivo
      (celular/tablet).
    </li>
    <li>
      A correta utilização do aplicativo e dos serviços oferecidos, prezando
      pela boa convivência, pelo respeito e cordialidade entre os gestores e
      colaboradores.
    </li>
    <li>
      O cumprimento e respeito ao conjunto de regras dispostas neste Termo de
      Uso, no respectivo Aviso de Privacidade e na legislação nacional.
    </li>
    <li>A proteção dos dados de acesso à sua conta/perfil (login e senha).</li>
  </ul>

  <p>É de responsabilidade da GOINFRA:</p>
  <ul>
    <li>Indicar as características do serviço ou recurso.</li>
    <li>
      Os defeitos e vícios encontrados no serviço ou recurso oferecido, desde
      que lhe tenha dado causa.
    </li>
    <li>
      As informações que foram divulgadas pela organização, sendo que as
      informações divulgadas por você são de sua inteira responsabilidade.
    </li>
  </ul>
  <p>
    Não será permitido o uso de autoimagens (selfies) na coleta de imagens para
    registro de chegadas e saídas, nem qualquer imagem destinada a fins
    comerciais ou publicitários, ou que contenha conteúdos ilícitos, violentos,
    polêmicos, pornográficos, xenofóbicos, discriminatórios ou ofensivos.
  </p>
  <h3>6. Das sanções</h3>
  <p>
    Sem prejuízo das demais medidas legais cabíveis, a GOINFRA poderá, a
    qualquer momento, advertir, suspender ou cancelar a conta do usuário que:
  </p>
  <ul>
    <li>Que violar qualquer dispositivo do presente termo;</li>
    <li>Que descumprir os seus deveres como usuário;</li>
    <li>
      Que tiver qualquer comportamento fraudulento, doloso ou que ofenda a
      terceiros.
    </li>
  </ul>

  <h3>7. Da rescisão</h3>
  <p>
    A não observância das obrigações pactuadas nos termos de uso ou da
    legislação aplicável poderá, sem prévio aviso, ensejar a imediata rescisão
    unilateral por parte da GOINFRA e o bloqueio de todos os serviços prestados
    a você.
  </p>

  <h3>8. Das Alterações</h3>
  <p>
    Os itens descritos no presente instrumento poderão sofrer alterações,
    unilateralmente e a qualquer tempo, por parte da GOINFRA, para adequar ou
    modificar os serviços, bem como para atender novas exigências legais. As
    alterações serão veiculadas pelo site ou pelo aplicativo e você poderá optar
    por aceitar o novo conteúdo ou por cancelar o uso dos serviços.
  </p>

  <h3>9. Do suporte</h3>
  <p>
    Em caso de qualquer dúvida, sugestão ou problema com a utilização do
    aplicativo, você poderá entrar em contato com o suporte (LINK). O
    atendimento do suporte estará disponível de segunda a sexta feira, nos
    horários de funcionamento da agência (07:30 às 12:00 am, 14:00 às 17:30 pm).
  </p>

  <h3>10. Do foro</h3>
  <p>
    Para a solução de controvérsias decorrentes do presente documento será
    aplicado integralmente o direito brasileiro.
  </p>
  <p>
    Os eventuais litígios deverão ser apresentados no foro da comarca em que se
    encontra a sede da GOINFRA.
  </p>
</div>
