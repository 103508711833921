import { Component } from '@angular/core';

@Component({
  selector: 'app-termo-privacidade',
  templateUrl: './termo-privacidade.component.html',
  styleUrls: ['./termo-privacidade.component.css']
})
export class TermoPrivacidadeComponent {

}
