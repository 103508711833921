import { Component } from '@angular/core';

@Component({
  selector: 'app-termo-uso',
  templateUrl: './termo-uso.component.html',
  styleUrls: ['./termo-uso.component.css']
})
export class TermoUsoComponent {

}
