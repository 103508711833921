import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FuncoesAuxiliares } from 'src/app/utils/funcoesAuxiliares';
import { ViagensService } from './viagens.service';

@Component({
  selector: 'app-viagens',
  templateUrl: './viagens.component.html',
  styleUrls: ['./viagens.component.css'],
})
export class ViagensComponent {
  displayedColumns: string[] = [
    'chegada',
    'saida',
    'funcionario',
    'latitude',
    'longitude',
    'acao',
  ];
  dataSource = new MatTableDataSource<any>();
  itemsPerPage = 5;
  pageIndex = 0;

  selectFuncionario: any = [];
  selectEquipamento: any = [];
  filtroForm: FormGroup;

  loading: boolean = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    public dialog: MatDialog,
    private viagensService: ViagensService,
    private _fa: FuncoesAuxiliares,
    private _fb: FormBuilder
  ) {
    this.filtroForm = this._fb.group({
      dt_inicial: [''],
      dt_final: [''],
      funcionario: [''],
      pesquisa: [''],
    });
  }

  ngOnInit() {
    this.buscarViagens();
    this.buscarSelect();
  }

  onPageChange(event: PageEvent) {
    this.itemsPerPage = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.buscarViagens();
  }

  buscarViagens() {
    let formData = this.filtroForm.value;
    if (this.filtroForm.controls['dt_inicial'].value) {
      formData['dt_inicial'] = this._fa.formatDatetoString(
        new Date(this.filtroForm.controls['dt_inicial'].value)
      );
    }

    if (this.filtroForm.controls['dt_final'].value) {
      formData['dt_final'] = this._fa.formatDatetoString(
        new Date(this.filtroForm.controls['dt_final'].value)
      );
    }

    this.viagensService
      .buscarPorFiltro(formData, this.itemsPerPage, this.pageIndex + 1)
      .subscribe((response: any) => {
        this.dataSource = new MatTableDataSource(response.data);
        this.paginator.length = response.total;
      });
  }

  handleFilters() {
    let filtros: any = {};
    if (
      this.filtroForm.controls['dt_inicial'].value &&
      this.filtroForm.controls['dt_final'].value
    ) {
      filtros['dt_inicio'] = this._fa.formatDatetoString(
        new Date(this.filtroForm.controls['dt_inicial'].value)
      );
      filtros['dt_final'] = this._fa.formatDatetoString(
        new Date(this.filtroForm.controls['dt_final'].value)
      );
    }
    return filtros;
  }

  buscarSelect() {
    this.viagensService.usuarios().subscribe((response: any) => {
      this.selectFuncionario = response;
    });
  }

  limparFiltro() {
    this.filtroForm.reset({
      dt_inicial: '',
      dt_final: '',
      funcionario: '',
      pesquisa: '',
    });

    this.buscarViagens();

    this.itemsPerPage = 5;
    this.pageIndex = 0;
    this.dataSource = new MatTableDataSource<any>();
    this.paginator.length = 0;
  }

  formataData(date: Date) {
    return this._fa.formatDatetoString(date);
  }

  abrirArquivo(row: any): void {
    if (row.id) {
      this.viagensService.abrirDocumento(row.usuario.id, row.id);
    } else {
      console.log('Sem anexo ou id encontrado!');
    }
  }
}
