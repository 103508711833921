<footer class="main-footer">
  <span class="footer-logo">
    <img class="logo" src="assets/logo-rodape.svg" alt="Logo" />
  </span>
  <div class="footer-group">
    <span class="footer-cinza"
      >Copyright &#169; 2024 - Todos os direitos reservados</span
    >
    <span class="footer-dti"
      >Desenvolvido pela Gerência da Tecnologia - GOINFRA</span
    >
  </div>
</footer>
