<section class="vh-100">
  <div class="container-fluid h-100">
    <div class="row h-100 align-items-center">
      <div
        class="col-lg-7 col-md-12 d-flex justify-content-center align-items-center mb-4 mb-lg-0"
      >
        <img src="/assets/aside.png" class="img-fluid" alt="Phone image" />
      </div>

      <div
        class="order-first order-md-last col-lg-4 col-md-12 d-flex justify-content-center"
      >
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="w-100 px-3">
          <div class="input-group input-group-lg mb-3">
            <input
              class="form-control"
              type="text"
              formControlName="username"
              id="username"
              placeholder="Usuário"
            />
            <div class="input-group-append">
              <span
                id="showPasswordIcon"
                class="input-group-text"
                (click)="hide = !hide"
                style="cursor: pointer"
              >
                <i class="fa fa-envelope" alt="Toggle Password Visibility"></i>
              </span>
            </div>
          </div>

          <div class="input-group input-group-lg mb-3">
            <input
              class="form-control"
              [type]="hide ? 'password' : 'text'"
              id="password"
              formControlName="password"
              placeholder="Senha"
            />
            <div class="input-group-append">
              <span
                id="showPasswordIcon"
                class="input-group-text"
                (click)="hide = !hide"
                style="cursor: pointer"
              >
                <i class="fa fa-lock" alt="Toggle Password Visibility"></i>
              </span>
            </div>
          </div>

          <div class="d-flex justify-content-around align-items-center mb-4">
            <a (click)="openDialog()">Esqueceu a senha?</a>
          </div>

          <button
            type="submit"
            [disabled]="loading"
            class="btn btn-success btn-lg btn-block"
          >
            {{ loading ? "Carregando..." : "Entrar" }}
          </button>
        </form>
      </div>
    </div>
  </div>
  <footer>
    <span class="footer-logo">
      <img class="logo" src="assets/logo-rodape.svg" alt="Logo" />
    </span>
    <div class="footer-group">
      <span class="footer-cinza"
        >Copyright &#169; 2024 - Todos os direitos reservados</span
      >
      <span class="footer-dti"
        >Desenvolvido pela Gerência da Tecnologia - GOINFRA</span
      >
    </div>
  </footer>
</section>
