import { NgModule } from '@angular/core';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home/home.component';
import { SharedModule } from '../shared/shared.module';
import { ViagensComponent } from './viagens/viagens.component';
import {
  OWL_DATE_TIME_FORMATS,
  OwlDateTimeIntl,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from 'src/app/services/interceptor/AuthInterceptor';
import { MapComponent } from 'src/app/components/map/map.component';

export const MY_MOMENT_FORMATS = {
  parseInput: 'DD/MM/YYYY HH:mm',
  fullPickerInput: 'DD/MM/YYYY HH:mm',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'HH:mm:ss',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

export class DefaultDateTimeIntl extends OwlDateTimeIntl {
  override cancelBtnLabel = 'Cancelar';
  override setBtnLabel = 'Selecionar';
}

@NgModule({
  declarations: [HomeComponent, ViagensComponent, MapComponent],
  imports: [
    SharedModule,
    HomeRoutingModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
  ],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
    { provide: OwlDateTimeIntl, useClass: DefaultDateTimeIntl },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class HomeModule {}
