import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormLoginComponent } from './form-login/form-login.component';
import { LoginEsqueciSenhaComponent } from './login-esqueci-senha/login-esqueci-senha.component';
import { LoginRoutingModule } from './login-routing.module';

@NgModule({
  declarations: [FormLoginComponent, LoginEsqueciSenhaComponent],
  imports: [SharedModule, LoginRoutingModule],
  exports: [FormLoginComponent, LoginEsqueciSenhaComponent],
})
export class LoginModule {}
