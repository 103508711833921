import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent {
  payloadToken;
  currentRoute: string = '';
  perfil;
  public menu = MENU;

  constructor(private authService: AuthService, private router: Router) {
    this.payloadToken = authService.obterDadosPayload();
    this.perfil = this.payloadToken.perfil;

    // Verifica a rota ativa atual
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.urlAfterRedirects;
      });
  }

  // Método para verificar se a rota está ativa
  isActive(route: string): boolean {
    return this.currentRoute === route;
  }

  transform(value: string, words: number): string {
    const regex = new RegExp(`((\\W*\\w+){0,${words}})`);
    const result = value.match(regex)[1];
    return result.length < value.length ? result : result;
  }
  isMenuOpen(item: any): boolean {
    if (item.children) {
      return item.children.some((child: any) => this.isActive(child.path?.[0]));
    }
    return false;
  }
}
export const MENU = [
  {
    name: 'Dashboard',
    iconClasses: 'fas fa-chart-line',
    path: ['/'],
  },
  {
    name: 'Gerenciar Viagens',
    iconClasses: 'fa-solid fa-car-side',
    path: ['/viagens'],
  },
  {
    name: 'Relatórios',
    iconClasses: 'fas fa-file-lines',
    path: ['/relatorios'],
  },
  {
    name: 'SRE',
    iconClasses: 'fas fa-route',
    children: [
      {
        name: 'Listar',
        path: ['/sre/listar'],
        iconClasses: 'fas fa-route',
      },
      {
        name: 'Mapa',
        path: ['/sre/mapa'],
        iconClasses: 'fas fa-map',
      },
    ],
  },
  {
    name: 'Configuração',
    iconClasses: 'fas fa-gear',
    children: [
      {
        name: 'Perfil',
        path: ['/perfil'],
        iconClasses: 'fas fa-user-check mr-1',
      },
      {
        name: 'Usuário',
        path: ['/usuario'],
        iconClasses: 'fas fa-users',
      },
    ],
  },
];
