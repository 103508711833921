import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface SucessoDialogData {
  message: string;
  class: string;
}

@Component({
  selector: 'app-modal-sucesso',
  templateUrl: './modal-sucesso.component.html',
  styleUrls: ['./modal-sucesso.component.css'],
})
export class ModalSucessoComponent {
  class: string;
  message: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SucessoDialogData,
    public dialogRef: MatDialogRef<ModalSucessoComponent>
  ) {
    this.message = this.data.message;
    this.class =
      this.data?.class && this.data?.class == 'btn btn-success'
        ? 'mat-button-error'
        : 'btn btn-success';
  }

  confirm() {
    this.dialogRef.close('success');
  }
}
