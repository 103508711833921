<div class="container">
  <mat-dialog-content>
    <span>{{ data.message }}</span>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button style="width: 30%" mat-raised-button (click)="confirm()">
      Sim
    </button>
    <button
      style="width: 30%"
      class="mat-button-verde"
      mat-raised-button
      (click)="cancel()"
    >
      Não
    </button>
  </mat-dialog-actions>
</div>
