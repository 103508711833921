import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from 'src/app/components/layout/layout.component';
import { NotFoundComponent } from 'src/app/components/not-found/not-found.component';
import { HomeComponent } from './home/home.component';
import { ViagensComponent } from './viagens/viagens.component';
import { authGuard } from 'src/app/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        canActivate: [authGuard],
        component: HomeComponent,
        title: 'Home',
      },
      {
        path: 'viagens',
        canActivate: [authGuard],
        component: ViagensComponent,
        title: 'Viagens',
      },
      { path: '**', canActivate: [authGuard], component: NotFoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
