<nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
        <li class="nav-item d-none d-sm-inline-block">
            <a href="#" class="nav-link">Home</a>
        </li>
    </ul>
    <div class="ml-auto mr-3 mt-3">
        <a class="nav-link fonte-14-negrito cursor-pointer" (click)="logout()">
            Sair <i class="fas fa-sign-out-alt"></i>
        </a>
    </div>
</nav> 