import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class FuncoesAuxiliares {
  private datePipe: DatePipe;

  constructor() {
    this.datePipe = new DatePipe('pt-BR'); // Pode especificar um local apropriado aqui
  }

  formataData(date: Date) {
    if (date) {
      let data = new Date(date);
      let dataFormatada =
        data.getDate() + '/' + (data.getMonth() + 1) + '/' + data.getFullYear();
      return dataFormatada;
    }
    return null;
  }

  formataMesAnoPorExtenso(date: Date) {
    if (date) {
      let data = new Date(date);
      let dataFormatada = this.datePipe.transform(data, 'MMMM/yyyy');
      return dataFormatada;
    }
    return null;
  }

  extrairHoraMinutos(dataString: string): string {
    const partes = dataString.split(' ');
    if (partes.length === 2) {
      const horaMinutos = partes[1].substring(0, 5);
      return horaMinutos;
    }
    return '';
  }

  extrairMesAno(dataString: string): string {
    const partes = dataString.split(' ');
    if (partes.length === 2) {
      const dataPartes = partes[0].split('-');
      if (dataPartes.length === 3) {
        const ano = dataPartes[0];
        const mes = dataPartes[1];
        return `${mes}/${ano}`;
      }
    }
    return '';
  }

  extrairDiaMesAno(dataString: string): string {
    const partes = dataString.split(' ');
    if (partes.length === 2) {
      const dataPartes = partes[0].split('-');
      if (dataPartes.length === 3) {
        const ano = dataPartes[0];
        const mes = dataPartes[1];
        const dia = dataPartes[2];
        return `${dia}/${mes}/${ano}`;
      }
    }
    return '';
  }

  formatDateMY(dataString: string): Date | null {
    try {
      const [mes, ano] = dataString.split('/');
      console.log(mes, ano);
      const data = new Date(parseInt(ano), parseInt(mes) - 1, 1);
      return data;
    } catch (error) {
      return null;
    }
  }

  // formatDateDMY(dataString: string): Date | null {
  //   try {
  //     const partes = dataString.split('/');
  //     if (partes.length === 3) {
  //       const dia = parseInt(partes[0], 10);
  //       const mes = parseInt(partes[1], 10) - 1;
  //       const ano = parseInt(partes[2], 10);

  //       if (!isNaN(dia) && !isNaN(mes) && !isNaN(ano)) {
  //         const data = new Date(ano, mes, dia);
  //         return data;
  //       }
  //     }
  //   } catch (error) {
  //     return null;
  //   }
  // }

  formatDatetoString(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  dataInicioMenorOuIgual(control: AbstractControl): ValidationErrors | null {
    const dataInicio = control.get('data_inicio')?.value;
    const dataFim = control.get('data_fim')?.value;

    if (dataInicio && dataFim) {
      const dataInicioDate = new Date(dataInicio);
      const dataFimDate = new Date(dataFim);

      if (dataInicioDate && dataFimDate && dataInicioDate > dataFimDate) {
        return { dataInicioMaior: true };
      }
    }
    return null;
  }

  dataValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const regex =
      /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(2\d{3}|[0-2]\d{3})$/;

    if (value && !regex.test(value)) {
      return { invalidFormat: true };
    }

    return null;
  }

  mesValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value.trim();
    const regex = /^(0[1-9]|1[0-2])\/\d{4}$/;

    if (value && !regex.test(value)) {
      return { invalidFormat: true };
    }

    return null;
  }
}
