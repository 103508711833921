import { NgModule } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { LayoutComponent } from 'src/app/components/layout/layout.component';
import { NotFoundComponent } from 'src/app/components/not-found/not-found.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { HomeModule } from '../home/home.module';
import { FooterComponent } from 'src/app/components/layout/footer/footer.component';
import { NavbarComponent } from 'src/app/components/layout/navbar/navbar.component';
import { SidebarComponent } from 'src/app/components/layout/sidebar/sidebar.component';
import { ContentComponent } from 'src/app/components/layout/content/content.component';
import { LoginModule } from '../login/login.module';
import { AuthService } from 'src/app/services/auth.service';
import { ModalConfirmacaoComponent } from 'src/app/components/modal/modal-confirmacao/modal-confirmacao.component';
import { ModalSucessoComponent } from 'src/app/components/modal/modal-sucesso/modal-sucesso.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NavbarComponent,
    SidebarComponent,
    ContentComponent,
    FooterComponent,
    NotFoundComponent,
    ModalConfirmacaoComponent,
    ModalSucessoComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    LoginModule,
    HomeModule,
  ],
  providers: [AuthService],
})
export class CoreModule {}
