<div style="padding: 1% 3%">
  <div class="container-header">
    <h5 style="color: #212529">Recuperar minha senha</h5>
    <button mat-icon-button (click)="closeModal()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="container-content">
    <div
      class="row d-flex flex-column align-items-center justify-content-center h-100"
    >
      <div class="col-md-8 col-lg-7 col-xl-6 text-center">
        <img
          src="/assets/logo.svg"
          class="img-fluid"
          alt="Phone image"
          width="304"
          height="71"
        />
      </div>
      <div class="col-md-8 col-lg-7 col-xl-6">
        <span style="font-size: 18px; font-weight: 400">
          Para solicitação de nova senha acesse o GLPI para abertura de um
          chamado clicando
          <a
            href="http://glpi.goinfra.go.gov.br/"
            target="_blank"
            style="font-size: 20px; font-weight: 700"
            >aqui</a
          >
          ou solicite seu gestor para que seja criado o chamado</span
        >
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end" class="button-group">
    <a
      href="http://glpi.goinfra.go.gov.br/"
      target="_blank"
      class="btn btn-success mx-2"
    >
      <i class="bi bi-check px-1"></i>
      <span class="ml-2">Abrir Chamado</span>
    </a>
  </mat-dialog-actions>
</div>
