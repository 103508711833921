import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './modules/core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TermoPrivacidadeComponent } from './modules/termo-privacidade/termo-privacidade.component';
import { TermoUsoComponent } from './modules/termo-uso/termo-uso.component';
import { RouterModule } from '@angular/router';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { PaginatorService } from './services/paginator/paginator.service';

//Não realizar nenhum import aqui, esse módulo é responsável apenas pelo Start da aplicação
// Para realizar um import por exemplo: mat-card, faça no Shared module;
// Os módulos devem ser declarados no coreModule, os componentes que não tem módulo pai também deverão
//ser importados no coreModule;

@NgModule({
  declarations: [TermoPrivacidadeComponent, TermoUsoComponent],
  imports: [CoreModule, BrowserAnimationsModule, RouterModule.forRoot([])],
  providers: [
    {
      provide: MatPaginatorIntl,
      useFactory: PaginatorService.getPaginatorIntl,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
