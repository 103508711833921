import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { FormLoginComponent } from './modules/login/form-login/form-login.component';
import { TermoPrivacidadeComponent } from './modules/termo-privacidade/termo-privacidade.component';
import { TermoUsoComponent } from './modules/termo-uso/termo-uso.component';

const routes: Routes = [
  { path: 'login', component: FormLoginComponent },
  { path: 'aviso-privacidade', component: TermoPrivacidadeComponent },
  { path: 'termos-uso', component: TermoUsoComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'usuario',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'perfil',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/perfil/perfil.module').then((m) => m.PerfilModule),
  },
  {
    path: 'sre',
    canActivate: [authGuard],
    loadChildren: () =>
      import('./modules/sre/sre.module').then((m) => m.SreModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
