<div class="container mt-4">
  <h1 class="text-center">Termos de Uso</h1>
  <p class="text-end">Data: 10 de outubro de 2024</p>

  <h3>1. Introdução</h3>
  <p>Bem-vindo ao aplicativo GOINFRA Viagens.</p>
  <p>
    Agradecemos por usar o aplicativo de viagens da Agência Goiana de
    Infraestrutura e Transportes – GOINFRA e os serviços e recursos que
    disponibilizamos a vocês como parte dela (coletivamente, o “Serviço”). Por
    favor, leia atentamente os Termos de Uso e o
    <a [routerLink]="['/aviso-privacidade']">Aviso de Privacidade</a>
    antes de utilizar este site/aplicativo.
  </p>

  <h3>2. Nosso Serviço</h3>
  <p>
    Este aplicativo de registro de viagens foi elaborado para otimizar os
    registros de viagens e relatórios de atividades realizadas pelas equipes que
    atuam nas obras. Este serviço permite que você realize o registro de
    chegadas e saídas em atividades externas.
  </p>

  <h3>3. Propriedade do site e condições de Uso</h3>
  <p>
    Este aplicativo é propriedade da Agência Goiana de Infraestrutura e
    Transportes – GOINFRA, com sede na Avenida Governador José Ludovico de
    Almeida, 20 - Conj. Caiçara, Goiânia - GO, 74775-013, inscrita no CNPJ/MF
    sob nº 03.520.933/0001-06. Os direitos autorais e responsabilidade pelo
    conteúdo neste aplicativo são de total e irrestrita propriedade da GOINFRA.
  </p>

  <p>
    Estes termos de uso também está subordinado ao aviso de privacidade. Caso
    não concorde com as disposições destes termos de uso e da ciência ao aviso
    de privacidade para utilização do aplicativo, você não deve utilizá-lo.
  </p>
  <p>
    Ao utilizar este aplicativo você concorda integralmente com as presentes
    normas e compromete-se a observá-las, sob o risco de aplicação de penalidade
    cabíveis.
  </p>

  <h3>Do cadastro e acesso</h3>
  <p>
    O acesso às funcionalidades do aplicativo será disponibilizado apenas aos
    usuários que possuem as credenciais de acesso (login e senha). Caso você não
    possua estas credenciais, ou ocorra erro no registro de acesso ao
    site/aplicativo, você deverá entrar em contato com o nosso
    <a href="http://glpi.goinfra.go.gov.br/">suporte</a>.
  </p>

  <p>
    Você é responsável por garantir a exatidão e por manter a segurança de suas
    informações de identificação. Você também é responsável por todas as
    atividades que ocorrem sob sua conta ou senha. Se você achar que há
    possíveis problemas relacionados à segurança de sua conta no aplicativo,
    informe-nos imediatamente para que possamos tomar as medidas necessárias.
  </p>

  <p>
    Por meio do seu endereço de e-mail funcional, o aplicativo realizará todas
    as comunicações necessárias com você. Cabe a você assegurar que o seu
    equipamento seja compatível com as características técnicas que viabilize a
    utilização do site/aplicativo e dos serviços. Você poderá, a qualquer tempo,
    requerer a alteração de seu cadastro junto ao aplicativo. A solicitação de
    alteração será analisada pela equipe do suporte, depois de verificada a sua
    situação funcional.
  </p>

  <p>
    Serão utilizadas todas as soluções técnicas à disposição da GOINFRA para
    permitir o registro das informações de localização e anexação de documentos
    no período das 07 a.m até às 07 p.m, bem como, o acesso às informações do
    site/aplicativo (vinte e quatro) horas por dia, 7 (sete) dias por semana. No
    entanto, a navegação poderá ser interrompida, limitada ou suspensa para
    atualizações, modificações ou qualquer ação necessária ao seu bom
    funcionamento.
  </p>

  <h3>5. Responsabilidades</h3>
  <p>É de sua responsabilidade:</p>
  <ul>
    <li>
      Defeitos ou vícios técnicos originados no seu computador/celular ou
      qualquer outro dispositivo utilizado para acesso ao site/aplicativo.
    </li>
    <li>
      A correta utilização do site/aplicativo, dos serviços oferecidos, prezando
      pela boa convivência, respeito e cordialidade entre os gestores e
      colaboradores.
    </li>
    <li>
      Pelo cumprimento e respeito ao conjunto de regras disposto neste Termo de
      Uso, no respectivo Aviso de Privacidade e na legislação nacional.
    </li>
    <li>
      Pela proteção aos dados de acesso à sua conta/perfil (login e senha).
    </li>
  </ul>

  <p>É de responsabilidade da GOINFRA:</p>
  <ul>
    <li>Indicar as características do serviço ou recurso.</li>
    <li>
      Responder por defeitos e vícios encontrados no serviço ou recurso
      oferecido, desde que lhe tenha dado causa.
    </li>
    <li>
      As informações que foram divulgadas pela organização, sendo que
      informações divulgadas por você são de sua inteira responsabilidade.
    </li>
    <li>
      Não poderão ser adicionadas imagens que sirvam para fins comerciais ou
      publicitários, ou quaisquer informações ilícitas, violentas, polêmicas,
      pornográficas, xenofóbicas, discriminatórias ou ofensivas.
    </li>
  </ul>
  <p>
    Não poderão ser adicionados imagem que sirvam para fins comerciais ou
    publicitários ou quaisquer informações ilícitas, violentas, polêmicas,
    pornográficas, xenofóbicas, discriminatórias ou ofensivas.
  </p>
  <h3>6. Das sanções</h3>
  <p>
    Sem prejuízo das demais medidas legais cabíveis, a GOINFRA poderá, a
    qualquer momento, advertir, suspender ou cancelar a conta do usuário que:
  </p>
  <ul>
    <li>Violar qualquer dispositivo do presente Termo.</li>
    <li>Descumprir os seus deveres como usuário.</li>
    <li>
      Apresentar comportamento fraudulento, doloso ou que ofenda a terceiros.
    </li>
  </ul>

  <h3>7. Da rescisão</h3>
  <p>
    A não observância das obrigações pactuadas nos termos de uso ou da
    legislação aplicável poderá, sem prévio aviso, ensejar a imediata rescisão
    unilateral por parte da GOINFRA e o bloqueio de todos os serviços prestados
    a você.
  </p>

  <h3>8. Das Alterações</h3>
  <p>
    Os itens descritos no presente instrumento poderão sofrer alterações,
    unilateralmente e a qualquer tempo, por parte da GOINFRA, para adequar ou
    modificar os serviços, bem como para atender novas exigências legais. As
    alterações serão veiculadas pelo site ou pelo aplicativo e você poderá optar
    por aceitar o novo conteúdo ou por cancelar o uso dos serviços.
  </p>

  <h3>9. Do suporte</h3>
  <p>
    Em caso de qualquer dúvida, sugestão ou problema com a utilização do
    site/aplicativo, entre em contato com o suporte. Este serviço de atendimento
    estará disponível de segunda a sexta-feira no período da 07:30 am até às
    17:30 pm.
  </p>

  <h3>10. Do foro</h3>
  <p>
    Para a solução de controvérsias decorrentes do presente instrumento será
    aplicado integralmente o Direito brasileiro. Os eventuais litígios deverão
    ser apresentados no foro da comarca em que se encontra a sede da GOINFRA.
  </p>
</div>
