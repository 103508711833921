import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent {
  payloadToken;
  currentRoute: string = '';
  perfil;

  constructor(private authService: AuthService, private router: Router) {
    this.payloadToken = authService.obterDadosPayload();
    this.perfil = this.payloadToken.perfil;

    // Verifica a rota ativa atual
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.urlAfterRedirects;
      });
  }

  // Método para verificar se a rota está ativa
  isActive(route: string): boolean {
    return this.currentRoute === route;
  }
}
