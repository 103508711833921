import { Injectable } from '@angular/core';
import { StorageService } from '../auth/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AbilityService {
  constructor(private storageService: StorageService) {}

  hasMenu(ability: string): boolean {
    const userAbilities = this.storageService.getAbility();
    const filteredAbilities = userAbilities
      .filter((item) => item.VISUALIZAR === '1')
      .map((item) => item.funcionalidade.DS_MENU);

    return filteredAbilities.includes(ability);
  }

  hasVisualizar(ability: string): boolean {
    const userAbilities = this.storageService.getAbility();

    const filteredAbilities = userAbilities
      .filter((item) => item.VISUALIZAR === '1')
      .map((item) => item.funcionalidade.DS_FUNCIONALIDADE);

    return filteredAbilities.includes(ability);
  }

  hasIncluir(ability: string): boolean {
    const userAbilities = this.storageService.getAbility();
    const filteredAbilities = userAbilities
      .filter((item) => item.INCLUIR === '1')
      .map((item) => item.funcionalidade.DS_FUNCIONALIDADE);
    return filteredAbilities.includes(ability);
  }

  hasAlterar(ability: string): boolean {
    const userAbilities = this.storageService.getAbility();
    const filteredAbilities = userAbilities
      .filter((item) => item.ALTERAR === '1')
      .map((item) => item.funcionalidade.DS_FUNCIONALIDADE);
    return filteredAbilities.includes(ability);
  }
}
