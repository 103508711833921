import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  urlToken: string = environment.apiUrl + '/token/backoffice';
  jwtHelper: JwtHelperService;

  constructor(private http: HttpClient) {
    this.jwtHelper = new JwtHelperService();
  }

  obterToken(login: string, password: string): Observable<any> {
    const params = new HttpParams()
      .set('login', login)
      .set('password', password);

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    return this.http.post(this.urlToken, params.toString(), { headers });
  }

  isAuthenticated(): boolean {
    const token = this.obterTokenStorage();
    if (token) {
      const expirado = this.jwtHelper.isTokenExpired(token);
      return !expirado;
    }
    return false;
  }

  obterTokenStorage() {
    const tokenStr = localStorage.getItem('token');
    if (tokenStr) {
      const token = JSON.parse(tokenStr).token;
      return token;
    }
    return null;
  }

  obterDadosPayload() {
    const decodedToken = this.jwtHelper.decodeToken(this.obterTokenStorage());
    return decodedToken;
  }

  encerrarSessao() {
    localStorage.removeItem('token');
  }

  obterPerfil() {
    const decodedToken = this.jwtHelper.decodeToken(this.obterTokenStorage());
    console.log(decodedToken.perfil);
    return decodedToken.perfil;
  }
}
