import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private http: HttpClient) {}

  usuarios(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/usuarios`);
  }

  getData(filtro: any): Observable<any[]> {
    const httpParams = new HttpParams({
      fromObject: filtro,
    });

    return this.http.get<any[]>(`${environment.apiUrl}/dashboard/`, {
      params: httpParams,
    });
  }
}
