<div class="container mt-4">
  <h1 class="text-center">AVISO DE PRIVACIDADE</h1>
  <div>
    <h2>1. SOBRE ESTE AVISO DE PRIVACIDADE</h2>
    <p>
      Agência Goiana de Infraestrutura e Transportes - GOINFRA está comprometida
      com a proteção e segurança das suas informações pessoais. O presente aviso
      de privacidade tem o objetivo de esclarecer, de forma simples,
      transparente e objetiva, como se dá o tratamento das informações pessoais
      dos titulares visitantes/usuários do nosso aplicativo GOINFRA Viagens.
    </p>
    <p>
      Esta política se aplica quando a GOINFRA atua como controlador de dados em
      relação aos dados pessoais tratados; em outras palavras, quando
      determinamos as finalidades e os meios do processamento desses dados
      pessoais.
    </p>
    <p>
      A Agência Goiana de Infraestrutura e Transportes – GOINFRA, entidade da
      administração indireta do Poder Executivo do Estado de Goiás, CNPJ nº
      03.520.933/0001-06, é uma entidade autárquica estadual, com autonomia
      administrativa, financeira e patrimonial, jurisdicionada à
      Secretaria-Geral da Governadoria – SGG.
    </p>

    <h3>1.1 SÃO APLICÁVEIS A ESTE AVISO AS LEGISLAÇÕES SEGUINTES:</h3>
    <ul>
      <li>
        Lei federal nº 13.709, de 14 de agosto de 2018 - Lei Geral de Proteção
        de Dados Pessoais (LGPD) - Propõe o tratamento de dados pessoais, com o
        objetivo de proteger os direitos fundamentais de liberdade e de
        privacidade;
      </li>
      <li>
        Decreto estadual nº 10.092 de 06 de junho de 2022 - Dispõe sobre a
        aplicação da Lei federal nº 13.709, de 14 de agosto de 2018 - LGPD, no
        âmbito da administração pública direta e indireta do Poder Executivo
        estadual;
      </li>
      <li>
        Lei federal nº 12.965, de 23 de abril de 2014 - Marco Civil da Internet
        - Estabelece princípios, garantias, direitos e deveres para o uso da
        Internet no Brasil;
      </li>
      <li>
        Lei federal nº 12.527, de 18 de novembro de 2011 – Lei de Acesso à
        Informação – Regula o acesso a informações previsto na Constituição
        Federal;
      </li>
      <li>
        Lei federal nº 13.460, de 26 de junho de 2017 - Dispõe sobre
        participação, proteção e defesa dos direitos do usuário dos serviços
        públicos da administração pública.
      </li>
    </ul>

    <h3>2. OS TIPOS DE DADOS PESSOAIS QUE TRATAMOS</h3>
    <p>
      Quando você visita nosso aplicativo, podemos tratar as seguintes
      categorias de dados pessoais:
    </p>
    <ul>
      <li>
        <strong>Informações de sua conta de usuário:</strong> São dados
        fornecidos por você para acessar sua conta e os serviços prestados pelo
        aplicativo. Esses dados podem incluir sua identificação da conta, nome,
        telefone, endereço de e-mail, datas de criação e modificação da conta, e
        configurações do aplicativo.
      </li>
      <li>
        <strong>Informações de contato:</strong> São dados que nos permitem
        entrar em contato com você. Os dados podem incluir seu nome e endereço
        e-mail.
      </li>
      <li>
        <strong>Informações de viagem:</strong> São dados coletados quando você
        preenche o relatório de suas viagens, como: nome, imagem,
        latitude/longitude, localização, data e hora.
      </li>
      <li>
        <strong>Dados de identificação eletrônica:</strong> São dados geralmente
        coletados automaticamente, como: data e hora do acesso ao aplicativo da
        GOINFRA; device ID; páginas acessadas, dentre outros. 
      </li>
    </ul>

    <h3>3. COMO OBTEMOS SEUS DADOS PESSOAIS</h3>
    <p>
      Obtemos seus dados pessoais por meio do nosso aplicativo eletrônico,
      quando você acessa o aplicativo, ao realizar o(s) registro de viagem e
      quando você entra em contato com o nosso suporte do aplicativo.
    </p>

    <h3>4. PORQUE TRATAMOS SEUS DADOS PESSOAIS</h3>
    <p>
      Podemos tratar seus dados pessoais para o cumprimento das competências
      institucionais da GOINFRA, previstas no artigo 57 e seguintes da lei nº
      21.792/2023, que estabelece a organização administrativa do Poder
      Executivo Estadual.
    </p>
    <p>
      Ademais, podemos tratar seus dados pessoais para cumprimento de outras
      obrigações legais, como, por exemplo, para atender aos seus direitos de
      titular, conforme previsto na LGPD ou para cumprir ordens judiciais ou
      atender a requerimentos de outras autoridades públicas, conforme previsto
      em lei.
    </p>

    <h3>
      5. HIPÓTESES LEGAIS QUE AUTORIZA O NOSSO TRATAMENTO DE DADOS PESSOAIS
    </h3>
    <p>
      Os dados pessoais tratados pelo nosso aplicativo possuem fundamento em
      diferentes hipóteses legais, em conformidade dos arts. 7º e 11 da LGPD,
      sendo as principais:
    </p>
    <ul>
      <li>Cumprimento de obrigação legal ou regulatória pelo controlador;</li>
      <li>
        Tratamento pela administração pública, para o tratamento e uso
        compartilhado de dados necessários à execução de políticas públicas
        previstas em leis e regulamentos ou respaldadas em contratos, convênios
        ou instrumentos congêneres.
      </li>
    </ul>
    <p>
      Destaca-se que toda atividade de tratamento de dados pessoais realizada no
      âmbito da GOINFRA acontece conforme, pelo menos, uma base legal que o
      legitime.
    </p>

    <h3>6. ARMAZENAMENTO E O PERÍODO DE RETENÇÃO DE SEUS DADOS PESSOAIS</h3>
    <p>
      Nós armazenamos seus dados pessoais de forma segura, em infraestrutura
      tecnológica da administração pública estadual, conforme padrões de
      segurança aplicáveis à hipótese, e de maneira que favoreça os meios para o
      exercício de seus direitos previstos na LGPD.
    </p>
    <p>
      As informações coletadas pelo aplicativo GOINFRA Viagens serão mantidas
      pelo tempo necessário para o cumprimento da finalidade para a qual foram
      coletadas.  Sem prejuízo, as informações poderão ser conservadas para
      cumprimento de obrigação legal ou regulatória ou para uso exclusivo da
      GOINFRA, desde que realizada a anonimização dos dados pessoais.
    </p>
    <p>
      Após o término do período de armazenamento necessário, os dados serão
      excluídos ou anonimizados, conforme a legislação vigente.
    </p>

    <h3>7. COMPARTILHAMENTO DE SEUS DADOS PESSOAIS</h3>
    <p>
      Podemos compartilhar seus dados pessoais com organizações públicas ou
      privadas, respeitando as disposições da LGPD, em especial o princípio da
      necessidade e sempre de forma compatível com a(s) finalidade(s) para a(s)
      qual(is) foram coletados, conforme indicado na Seção 5.
    </p>
    <p>
      Também podemos compartilhar ou divulgar dados pessoais quando necessário
      para atender ao princípio da publicidade administrativa, nos termos da Lei
      nº 12.527, de 18 de novembro de 2011 (Lei de Acesso à Informação - LAI).
    </p>

    <h3>8. ELIMINAÇÃO DE SEUS DADOS PESSOAIS</h3>
    <p>
      Seus dados pessoais serão eliminados quando tiverem cumprido a(s)
      finalidade(s) para a(s) qual(is) forem coletados, observadas as tabelas de
      temporalidade aplicáveis e as regras de término de tratamento, eliminação
      e hipóteses de conservação de dados, nos termos dos artigos 15 e 16 da
      LGPD.
    </p>

    <h3>9. PROTEÇÃO DE SEUS DADOS PESSOAIS</h3>
    <p>
      Seus dados pessoais tratados pelo nosso aplicativo são protegidos por meio
      de medidas e soluções técnicas de proteção apropriadas para garantir a
      confidencialidade, integridade e inviolabilidade dos dados pessoais. Para
      manter os dados pessoais protegidos, são usadas ferramentas físicas,
      eletrônicas e gerenciais direcionadas para a proteção e privacidade.
    </p>
    <p>
      Em caso de incidentes de segurança que possam gerar risco ou dano
      relevante para os usuários, a GOINFRA irá comunicar os titulares,
      principalmente aquele afetados diretamente, a Autoridade Nacional de
      Proteção de Dados (ANPD), e atuará sobre o ocorrido em conformidade com as
      determinações da LGPD.
    </p>

    <h3>10. SEUS DIREITOS</h3>
    <p>
      De acordo com a Lei Geral de Proteção de Dados Pessoais – LGPD, o titular
      de dados possui os seguintes direitos:
    </p>
    <ul>
      <li>
        <strong>Confirmação</strong> de que a GOINFRA realiza o tratamento dos
        seus dados pessoais;
      </li>
      <li><strong>Acesso</strong> aos seus dados pessoais;</li>
      <li>
        <strong>Correção</strong> de dados pessoais incompletos, inexatos ou
        desatualizados;
      </li>
      <li>
        <strong>Anonimização, bloqueio ou eliminação</strong> de dados pessoais
        desnecessários, excessivos ou tratados em desconformidade com a LGPD; 
      </li>
      <li>
        <strong>Informação sobre compartilhamento</strong> de seus dados; 
      </li>
      <li>
        <strong
          >Informação sobre possibilidade de não fornecer consentimento,</strong
        >
        quando aplicável, e as consequências da negativa;   
      </li>
      <li>
        Revogação do consentimento, quando o tratamento de dados pessoais for
        realizado com base nesse fundamento;
      </li>
      <li>
        Eliminação dos dados pessoais com base no seu consentimento, exceto nos
        casos em que a LGPD autorize a conservação de dados pessoais;
      </li>
      <li>Oposição ao tratamento que viole a LGPD;</li>
      <li>
        Revisão de decisões automatizadas com base em dados pessoais que afetem
        os interesses dos titulares, inclusive as relacionadas à definição de
        seu perfil pessoal, profissional, de consumo, de crédito ou a aspectos
        de sua personalidade.
      </li>
    </ul>
    <p>
      Caso deseje exercer seus direitos, utilize a plataforma
      <strong>Portal Expresso</strong>, página
      <strong>Realizar Manifestação de Ouvidoria</strong>, aba pedido de
      informação pública/pessoal (<a
        href="https://www.go.gov.br/servicos-digitais/cge/nova-ouvidoria?openLaiModal=S"
      >
        https://www.go.gov.br/servicos-digitais/cge/nova-ouvidoria?openLaiModal=S </a
      >). O exercício de seus direitos é gratuito e a GOINFRA irá avaliar a
      possibilidade do imediato atendimento, e caso não seja possível, você será
      informado dos motivos ou dos prazos necessários.
    </p>

    <p>
      Se você tiver uma solicitação, reclamação ou queira contatar nossa
      encarregada pelo tratamento de dados pessoais (da sigla em inglês “DPO”
      que significa “Data Protection Officer”), Gleice Regina Nunes Silvério,
      poderá enviar um email para:
      <a href="mailto:encarregado&#64;goinfra.go.gov.br"
        >encarregado&#64;goinfra.go.gov.br</a
      >.
    </p>
  </div>
  <p class="text-end">Data: 17 de outubro de 2024</p>
</div>
