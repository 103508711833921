<div *ngIf="!loading">
  <div class="row">
    <div class="card col-12">
      <div class="card-header">
        <h3 class="card-title">Gerenciamento de Viagens</h3>
        <div class="card-tools">
          <button
            type="button"
            class="btn btn-tool"
            data-card-widget="collapse"
            title="Collapse"
          >
            <i class="fas fa-minus"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <form class="row mt-3" [formGroup]="filtroForm">
          <div class="col-md-3 col-12">
            <div class="form-group">
              <label>Data / hora chegada:</label>
              <div class="input-group">
                <div class="input-group-prepend" (click)="dt_inicial.open()">
                  <span class="input-group-text">
                    <i class="far fa-calendar-alt"></i>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control float-right"
                  [matDatepicker]="dt_inicial"
                  formControlName="dt_inicial"
                  id="dt_inicial"
                  readonly
                />
                <mat-datepicker #dt_inicial></mat-datepicker>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-12">
            <div class="form-group">
              <label>Data / hora saída:</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" (click)="dt_final.open()">
                    <i class="far fa-calendar-alt"></i>
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control float-right"
                  [matDatepicker]="dt_final"
                  formControlName="dt_final"
                  id="dt_final"
                  readonly
                />
                <mat-datepicker #dt_final></mat-datepicker>
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 col-lg-3">
            <div class="form-group">
              <label for="status">Funcionário</label>
              <select
                class="custom-select"
                id="status"
                name="status"
                formControlName="funcionario"
              >
                <option value="" selected disabled hidden>Selecione</option>
                <option
                  *ngFor="let funcionario of selectFuncionario"
                  [value]="funcionario.id"
                >
                  {{ funcionario.nome }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-6 col-md-3 col-lg-3">
            <div class="form-group">
              <label for="busca">Pesquisa</label>
              <input
                class="form-control"
                id="busca"
                name="busca"
                formControlName="pesquisa"
                placeholder="Digite"
              />
            </div>
          </div>
        </form>
        <div class="row mt-3">
          <div class="col-12 button-group">
            <button
              type="button"
              class="btn btn-warning"
              (click)="limparFiltro()"
            >
              <i class="fas fa-times"></i>
              <span class="ml-2">Limpar</span>
            </button>
            <button
              type="button"
              class="btn btn-success"
              (click)="buscarViagens()"
            >
              <i class="fas fa-search"></i>
              <span class="ml-2">Buscar</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="table-style">
    <ng-container matColumnDef="chegada">
      <th mat-header-cell *matHeaderCellDef>Data Chegada</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.registro_chegada?.data_hora | date : "dd/MM/yy HH:mm:ss" }}
      </td>
    </ng-container>
    <ng-container matColumnDef="saida">
      <th mat-header-cell *matHeaderCellDef>Data Saída</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.registro_saida?.data_hora | date : "dd/MM/yy HH:mm:ss" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="funcionario">
      <th mat-header-cell *matHeaderCellDef>Funcionário</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.usuario?.nome }}
      </td>
    </ng-container>

    <ng-container matColumnDef="latitude">
      <th mat-header-cell *matHeaderCellDef>Trecho (Chegada)</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.registro_chegada?.endereco }}
      </td>
    </ng-container>

    <ng-container matColumnDef="longitude">
      <th mat-header-cell *matHeaderCellDef>Trecho (Saída)</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.registro_saida?.endereco }}
      </td>
    </ng-container>
    <ng-container matColumnDef="acao">
      <th mat-header-cell *matHeaderCellDef>Opção</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          matTooltip="Visualizar"
          (click)="abrirArquivo(element)"
          class="button-icon"
        >
          <i class="fa fa-eye" style="font-size: 20px" aria-hidden="true"></i>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    [length]="dataSource.paginator?.length || 0"
    [pageIndex]="pageIndex"
    [pageSize]="itemsPerPage"
    [pageSizeOptions]="[5, 10, 25, 50]"
    (page)="onPageChange($event)"
    class="table-paginator"
  >
  </mat-paginator>
</div>

<div class="loading" *ngIf="loading" layout="row" layout-align="center center">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  <span class="loading-text">Carregando dados...</span>
</div>
