<aside class="main-sidebar sidebar-dark-primary elevation-1">
  <div class="d-flex justify-content-center align-items-center">
    <a
      href="/"
      class="d-flex justify-content-center align-items-center brand-link logo-container"
    >
      <img src="assets/logo.svg" alt="Logo" class="responsive-logo" />
      <p style="color: white; font-size: 20px; margin-left: 3px">Viagens</p>
    </a>
  </div>

  <div class="sidebar">
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img
          src="/assets/dist/img/default-profile.png"
          class="img-circle elevation-2"
          alt="User Image"
        />
      </div>
      <div class="info">
        <a href="/profile" class="d-block">
          {{ transform(payloadToken.nome, 2) }}
        </a>
      </div>
    </div>
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
        data-accordion="false"
      >
        <app-menu-item *ngFor="let item of menu" [menuItem]="item" />
      </ul>
    </nav>
  </div>
</aside>
